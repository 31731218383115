import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import "../css/Homepage.css";
import ArxivButton from "./homepage/ArxivButton";
import GithubButton from "./homepage/GithubButton";
import SlidesButton from "./homepage/SlidesButton";

const LoadingState = () => (
  <section className="home-section">
    <Container>
      <div className="loading-container">
        <div className="loading-bar">
          <div className="loading-progress"></div>
        </div>
        <p className="loading-text">Loading...</p>
      </div>
    </Container>
  </section>
);

const Home = () => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const imageRef = useRef(null);
  const [homepageData, setHomepageData] = useState(null);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const handleMouseUp = (e) => {
    e.target.classList.remove("active"); // Remove the active class manually
  };

  const [copySuccess, setCopySuccess] = useState(false);
  const email = "kharapan [at] asu [dot] edu";

  const handleCopyEmail = () => {
    // Copy the email text to the clipboard
    navigator.clipboard.writeText(
      email.replace(" [at] ", "@").replace(" [dot] ", ".")
    );
    setCopySuccess(true);

    // Reset after a short delay
    setTimeout(() => setCopySuccess(false), 500);
  };

  console.log(`${process.env.REACT_APP_BASE_API_URL}/api/homepage/`);
  useEffect(() => {
    const fetchHomepageData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_API_URL}/api/homepage/`
        );
        setHomepageData(response.data);
      } catch (error) {
        console.error("Error fetching homepage data:", error);
      }
    };

    fetchHomepageData();
  }, []);

  const getImageUrl = (imageUrl) => {
    if (!imageUrl) return "";
    if (imageUrl.startsWith("http")) return imageUrl;
    return `${process.env.REACT_APP_BASE_API_URL}${imageUrl}`;
  };

  // Add a helper function to format the date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
    });
  };

  if (!homepageData) {
    return <LoadingState />;
  }

  return (
    <section className="home-section">
      <Container>
        <Row className="align-items-center">
          <Col md={6} className="text-start">
            <h1>
              <strong>{homepageData.name}</strong>
            </h1>
          </Col>
        </Row>

        <Row>
          <Col md={4} className="text-md-end text-center order-md-last">
            <img
              ref={imageRef}
              src={getImageUrl(homepageData.profile_image)}
              alt={homepageData.name}
              className={`img-fluid ${!imageLoaded ? "loading" : ""}`}
              onLoad={handleImageLoad}
              loading="lazy"
            />
            <div className="email-box mt-3">
              <p>{homepageData.current_position}</p>
              <p>{homepageData.school}</p>
              <p className="mb-4">{homepageData.institution}</p>
              <p>
                Email:{" "}
                <a
                  href={`mailto:${homepageData.email}`}
                  className="email-link paper-link"
                >
                  {homepageData.email
                    .replace("@", " [at] ")
                    .replace(".", " [dot] ")}
                </a>
                <div className="copy-btn-wrapper">
                  <button className="copy-btn" onClick={handleCopyEmail}>
                    <img
                      src={`${process.env.PUBLIC_URL}/Assets/images/copy.png`}
                      alt="Copy email"
                      className="copy-icon"
                    />
                  </button>
                  {copySuccess && (
                    <div className="copy-success-popup">Copied!</div>
                  )}
                </div>
              </p>
              <div className="social-icons mt-2">
                <a
                  href="https://github.com/kaustubhharapanahalli"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/Assets/images/github.png`}
                    alt="GitHub"
                    className="icon"
                  />
                </a>
                <a
                  href="https://linkedin.com/in/kmhalli"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/Assets/images/linkedin.png`}
                    alt="LinkedIn"
                    className="icon"
                  />
                </a>
                <a
                  href="https://scholar.google.com/citations?user=51Dqq8kAAAAJ&hl=en"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/Assets/images/googlescholar.svg`}
                    alt="Google Scholar"
                    className="icon"
                  />
                </a>
              </div>
            </div>
            <a
              href={homepageData.resume_file}
              download={`${homepageData.name}_Resume.pdf`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button className="mt-3 download-btn" onMouseUp={handleMouseUp}>
                Download Resume
              </Button>
            </a>
          </Col>

          {/* Text Content Column */}
          <Col md={7}>
            <div className="text-box">
              <div
                dangerouslySetInnerHTML={{
                  __html: homepageData.about_text,
                }}
              />
            </div>

            {/* Updates Section */}
            <div>
              <h3 className="text-start updates-box">
                <strong className="updates-section">Updates</strong>
              </h3>
              {homepageData.updates.map((update, index) => (
                <Row key={index} className="UpdateElement">
                  <Col md={3}>
                    <p>{formatDate(update.date)}</p>
                  </Col>
                  <Col md={9}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: update.content.replace(
                          /<a /g,
                          '<a class="paper-link" target="_blank" rel="noopener noreferrer" '
                        ),
                      }}
                    />
                  </Col>
                </Row>
              ))}
            </div>

            {/* Publications Section */}
            <div>
              <h3 className="text-start updates-box">
                <strong>Publications</strong>
              </h3>
              {homepageData.publications.map((publication, index) => (
                <Row key={index} className="mb-4">
                  <Col md={4}>
                    {publication.thumbnail && (
                      <p>
                        <a
                          href={publication.arxiv_link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={getImageUrl(publication.thumbnail)}
                            alt={publication.title}
                            className="img-fluid"
                          />
                        </a>
                      </p>
                    )}
                  </Col>
                  <Col md={8}>
                    <p className="paper-title">
                      <a
                        href={publication.arxiv_link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="paper-link"
                      >
                        {publication.title}
                      </a>
                    </p>
                    <p className="venue">
                      {publication.venue_link ? (
                        <a
                          href={publication.venue_link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="paper-link"
                        >
                          {publication.venue}
                        </a>
                      ) : (
                        publication.venue
                      )}
                    </p>
                    <p className="authors">
                      {publication.authors}
                      {publication.shared_first_author && (
                        <small className="footnote">
                          <sup>*</sup> - shared first authors
                        </small>
                      )}
                    </p>
                    <p className="paper-buttons">
                      {publication.arxiv_link && (
                        <ArxivButton link={publication.arxiv_link} />
                      )}
                      {publication.github_link && (
                        <GithubButton link={publication.github_link} />
                      )}
                      {publication.slides_file && (
                        <SlidesButton file={publication.slides_file} />
                      )}
                    </p>
                  </Col>
                </Row>
              ))}
            </div>
          </Col>
          <Col md={1}></Col>
        </Row>
      </Container>
    </section>
  );
};

export default Home;
