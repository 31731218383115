import React from "react";
import "../../css/Homepage.css"; // Ensure this CSS file contains the updated styles

const SlidesButton = ({ file }) => {
  const getFileUrl = (fileUrl) => {
    if (!fileUrl) return "";
    // If it's already an absolute URL, return it
    if (fileUrl.startsWith("http")) return fileUrl;
    // Otherwise, prepend the API URL
    return `${process.env.REACT_APP_BASE_API_URL}${fileUrl}`;
  };

  return (
    <a
      href={getFileUrl(file)}
      target="_blank"
      rel="noopener noreferrer"
      className="ppt-btn"
    >
      <img
        src={`${process.env.PUBLIC_URL}/Assets/images/pdf.png`}
        alt="Slides"
        style={{ width: "18px", height: "18px", marginRight: "5px" }}
      />
      <span>Slides</span>
    </a>
  );
};

export default SlidesButton;
