import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../css/Homepage.css";
import "../css/Resume.css";
import {
  getEducation,
  getExperience,
  getProjects,
  getResearch,
  getResearchInterests,
} from "../services/portfolioService";
import LoadingState from "./LoadingState";
import ArxivButton from "./homepage/ArxivButton";
import GithubButton from "./homepage/GithubButton";
import PosterButton from "./homepage/PosterButton";
import SlidesButton from "./homepage/SlidesButton";

// const CodeButton = ({ link }) => (
//   <a href={link} target="_blank" rel="noopener noreferrer" className="code-btn">
//     <span>Code</span>
//   </a>
// );

const Resume = () => {
  const [education, setEducation] = useState([]);
  const [research, setResearch] = useState([]);
  const [experience, setExperience] = useState([]);
  const [projects, setProjects] = useState([]);
  const [researchInterests, setResearchInterests] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          researchInterestsData,
          educationData,
          researchData,
          experienceData,
          projectsData,
        ] = await Promise.all([
          getResearchInterests(),
          getEducation(),
          getResearch(),
          getExperience(),
          getProjects(),
        ]);

        setResearchInterests(researchInterestsData);
        setEducation(educationData);
        setResearch(researchData);
        setExperience(experienceData);
        setProjects(projectsData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching portfolio data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const processHTML = (html) => {
    return html.replace(
      /<a /g,
      '<a class="paper-link" target="_blank" rel="noopener noreferrer" '
    );
  };

  const formatDate = (dateString) => {
    if (!dateString) return "Present";
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
    });
  };

  if (loading) {
    return <LoadingState />;
  }

  return (
    <Container>
      <Row>
        <Col md={2}></Col>
        <Col md={8}>
          <h2 className="font-weight-bold">Research Interests</h2>
          {researchInterests.length > 0 && (
            <div
              dangerouslySetInnerHTML={{
                __html: researchInterests[0].description,
              }}
            />
          )}
        </Col>
        <Col md={2}></Col>
      </Row>

      <Row>
        <Col md={2}></Col>
        <Col md={8}>
          <h2 className="font-weight-bold">Education</h2>
          {education.map((edu, index) => (
            <Row key={index} className="mb-3">
              <Col md={3} className="font-weight-bold">
                {new Date(edu.start_date).getFullYear()} -{" "}
                {edu.end_date
                  ? new Date(edu.end_date).getFullYear()
                  : "Present"}
                <br />
                GPA: {edu.gpa}
              </Col>
              <Col>
                <div className="education-section">
                  <p>
                    <strong>{edu.degree}</strong>
                    <br />
                    {edu.institution}
                  </p>
                </div>
                <div className="courses-section">
                  <strong>Relevant Courses:</strong>
                  <div dangerouslySetInnerHTML={{ __html: edu.courses }} />
                </div>
              </Col>
            </Row>
          ))}
        </Col>
        <Col md={2}></Col>
      </Row>

      <Row>
        <Col md={2}></Col>
        <Col md={8}>
          <h2 className="font-weight-bold">Research</h2>
          {research.map((item, index) => (
            <div key={index} className="research-item">
              <h5 className="mb-2">
                <strong>{item.title}</strong>
              </h5>
              <div
                className="research-description mb-2"
                dangerouslySetInnerHTML={{ __html: item.description }}
              />
              <p className="authors mb-2">
                {item.authors}
                {item.shared_first_author && (
                  <small className="footnote">
                    <sup>*</sup> - shared first authors
                  </small>
                )}
              </p>
              <div className="paper-buttons">
                {item.arxiv_link && <ArxivButton link={item.arxiv_link} />}
                {item.slides_file && <SlidesButton file={item.slides_file} />}
                {item.poster_file && <PosterButton file={item.poster_file} />}
                {item.code_link && <GithubButton link={item.code_link} />}
              </div>
            </div>
          ))}
        </Col>
        <Col md={2}></Col>
      </Row>

      <Row>
        <Col md={2}></Col>
        <Col md={8}>
          <h2 className="font-weight-bold">Work Experience</h2>
          {experience.map((exp, index) => (
            <Row key={index} className="experience-item">
              <Col>
                <Row>
                  <Col md={9} className="experience-title">
                    <h5 className="font-weight-bold">
                      {exp.title}, {exp.company}
                    </h5>
                  </Col>
                  <Col md={3} className="text-right">
                    <p>
                      {formatDate(exp.start_date)} - {formatDate(exp.end_date)}
                    </p>
                  </Col>
                </Row>
                <div
                  className="experience-section"
                  dangerouslySetInnerHTML={{
                    // __html: processHTML(exp.description),
                    __html: exp.description.replace(
                      /<a /g,
                      '<a class="paper-link" target="_blank" rel="noopener noreferrer" '
                    ),
                  }}
                />
              </Col>
            </Row>
          ))}
        </Col>
        <Col md={2}></Col>
      </Row>

      <Row>
        <Col md={2}></Col>
        <Col md={8}>
          <h2 className="font-weight-bold">Projects</h2>
          {projects.map((project, index) => (
            <Row key={index} className="project-item">
              <Col>
                <Row>
                  <Col md={8} className="project-title">
                    <h5 className="font-weight-bold">{project.title}</h5>
                    <small>{project.subtitle}</small>
                  </Col>
                  <Col md={4} className="text-right">
                    <p className="font-italic">
                      {project.term} {new Date(project.end_date).getFullYear()}
                    </p>
                  </Col>
                </Row>
                <div
                  className="project-section"
                  dangerouslySetInnerHTML={{
                    __html: processHTML(project.description),
                  }}
                />
                <p>
                  <strong>Skills:</strong> {project.skills}
                </p>
                {project.collaborators && (
                  <p>
                    <strong>Collaborators:</strong> {project.collaborators}
                  </p>
                )}
                {project.code_link && (
                  <div className="paper-buttons">
                    <GithubButton link={project.code_link} />
                  </div>
                )}
              </Col>
            </Row>
          ))}
        </Col>
        <Col md={2}></Col>
      </Row>
    </Container>
  );
};

export default Resume;
