import axios from "axios";

export const getEducation = async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_API_URL}/api/portfolio/education/`
  );
  return response.data;
};

export const getResearch = async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_API_URL}/api/portfolio/research/`
  );
  return response.data;
};

export const getExperience = async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_API_URL}/api/portfolio/experience/`
  );
  return response.data;
};

export const getProjects = async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_API_URL}/api/portfolio/projects/`
  );
  return response.data;
};

export const getResearchInterests = async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_API_URL}/api/portfolio/research-interests/`
  );
  return response.data;
};
