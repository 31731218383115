import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ArxivButton from "./homepage/ArxivButton";
import GithubButton from "./homepage/GithubButton";
import SlidesButton from "./homepage/SlidesButton";

const Publications = () => {
  const [publications, setPublications] = useState([]);

  useEffect(() => {
    const fetchPublications = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_API_URL}/api/publications/`
        );
        setPublications(response.data);
      } catch (error) {
        console.error("Error fetching publications:", error);
      }
    };

    fetchPublications();
  }, []);

  return (
    <Container>
      <h2 className="text-start updates-box">
        <strong>Publications</strong>
      </h2>
      {publications.map((publication) => (
        <Row key={publication.id} className="mb-4">
          <Col md={4}>
            {publication.thumbnail && (
              <img
                src={publication.thumbnail}
                alt={publication.title}
                className="img-fluid"
              />
            )}
          </Col>
          <Col md={8}>
            <p className="paper-title">
              <a
                href={publication.arxiv_link}
                target="_blank"
                rel="noopener noreferrer"
                className="paper-link"
              >
                {publication.title}
              </a>
            </p>
            <p className="authors">{publication.authors}</p>
            <p className="venue">
              {publication.venue_link ? (
                <a
                  href={publication.venue_link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="paper-link"
                >
                  {publication.venue}
                </a>
              ) : (
                publication.venue
              )}
            </p>
            <p className="paper-buttons">
              {publication.arxiv_link && (
                <ArxivButton link={publication.arxiv_link} />
              )}
              {publication.github_link && (
                <GithubButton link={publication.github_link} />
              )}
              {publication.slides_file && (
                <SlidesButton file={publication.slides_file} />
              )}
            </p>
          </Col>
        </Row>
      ))}
    </Container>
  );
};

export default Publications;
